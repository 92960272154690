.contact-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
}

.contact-banner {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
}

@media (min-width: 768px) {
  .contact-banner {
    height: 300px;
  }
}

.contact-banner-content {
  text-align: center;
}

.contact-banner-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .contact-banner-title {
    font-size: 48px;
  }
}

.contact-banner-subtitle {
  font-size: 16px;
}

@media (min-width: 768px) {
  .contact-banner-subtitle {
    font-size: 18px;
  }
}

.contact-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

@media (min-width: 768px) {
  .contact-content {
    flex-direction: row;
    padding: 40px 20px;
  }
}

.contact-text {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .contact-text {
    flex: 0 0 40%;
    margin-bottom: 0;
  }
}

.contact-subtitle {
  font-size: 16px;
  color: #8a2be2;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .contact-subtitle {
    font-size: 18px;
  }
}

.contact-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

@media (min-width: 768px) {
  .contact-title {
    font-size: 42px;
  }
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .contact-info {
    gap: 30px;
  }
}

.contact-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

@media (min-width: 768px) {
  .contact-item {
    gap: 20px;
  }
}

.contact-icon {
  background-color: white;
  border-radius: 50%;
  border: 3px solid #8a2be2;
  color: #8a2be2;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .contact-icon {
    width: 60px;
    height: 60px;
    font-size: 24px;
    border-width: 4px;
  }
}

.contact-details {
  color: gray;
}

.contact-label {
  font-size: 14px;
  margin-bottom: 5px;
  position: relative;
  display: inline-block;
}

@media (min-width: 768px) {
  .contact-label {
    font-size: 16px;
  }
}

.contact-label::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #8a2be2;
  transition: width 0.3s ease;
}

.contact-item:hover .contact-label::after {
  width: 100%;
}

.contact-item:hover .contact-label {
  color: #8a2be2;
}

.contact-value {
  font-size: 16px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .contact-value {
    font-size: 18px;
  }
}

.contact-form {
  background-color: rgb(244, 242, 249);
  padding: 20px;
}

@media (min-width: 768px) {
  .contact-form {
    flex: 1;
  }
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .form-row {
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
  }
}

.form-row input,
.form-row textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

@media (min-width: 768px) {
  .form-row input,
  .form-row textarea {
    padding: 15px;
  }
}

.form-row input:focus,
.form-row textarea:focus {
  outline: none;
  border-color: #8a2be2;
  box-shadow: 0 0 0 2px rgba(138, 43, 226, 0.2);
}

.form-row button {
  width: 100%;
  padding: 12px;
  background-color: #8a2be2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

@media (min-width: 768px) {
  .form-row button {
    padding: 15px;
  }
}

.form-row button:hover {
  background-color: #000;
  transform: translateY(-2px);
}

.get-started-section {
  padding: 20px;
}

@media (min-width: 768px) {
  .get-started-section {
    padding: 40px 20px;
  }
}

.get-started-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.get-started-overlay {
  background-image: url('../assets/images/AboutUs_img.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 30px;
  display: flex;
  align-items: center;
  border-left: 5px solid rgb(184, 130, 252);
  background-color: rgba(72, 67, 81, 0.7);
}

@media (min-width: 768px) {
  .get-started-overlay {
    padding: 40px;
    border-left-width: 10px;
  }
}

.get-started-content {
  text-align: left;
}

.get-started-content h3 {
  color: rgb(184, 130, 252);
  margin-bottom: 10px;
  font-size: 16px;
}

.get-started-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .get-started-content h2 {
    font-size: 36px;
  }
}

.get-started-content button {
  padding: 10px 20px;
  background-color: #8a2be2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

@media (min-width: 768px) {
  .get-started-content button {
    font-size: 16px;
  }
}

.get-started-content button:hover {
  background-color: #000;
}

.map-section {
  padding: 20px 0;
  background-color: #f8f8f8;
}

@media (min-width: 768px) {
  .map-section {
    padding: 40px 0;
  }
}

.map-container {
  width: 100%;
  height: 300px;
  background: url('../assets/images/map_pic.jpg') no-repeat center center;
  background-size: cover;
}

@media (min-width: 768px) {
  .map-container {
    height: 400px;
  }
}