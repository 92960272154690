/* src/components/BrandSection.css */
.brand-section {
    padding: 40px 0;
    border-top: 1px solid #e0e0e0; /* Thin gray line separator */
  }
  
  .brand-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .brand-text-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .brand-text {
    font-size: 24px;
    color: #a0a0a0; /* Faint gray color */
    margin: 0 20px;
    padding: 10px 0;
  }
  
  @media (max-width: 768px) {
    .brand-text {
      font-size: 20px;
      margin: 0 10px;
    }
  }
  
  @media (max-width: 480px) {
    .brand-text {
      font-size: 18px;
      margin: 0 5px;
    }
  }