body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  min-height: 100vh;
}

.offerings-ready-container {
  position: relative;
}