/* src/components/ProjectsSection.css */
.projects-section {
    padding: 80px 0;
    background-color: #f8f8f8;
  }
  
  .projects-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .projects-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  
  .projects-subtitle {
    color: #8a2be2;
    font-size: 18px;
    margin: 0;
  }
  
  .projects-summary {
    font-size: 16px;
    max-width: 50%;
    text-align: right;
  }
  
  .projects-title {
    font-size: 36px;
    font-weight: bold;
    color: #000;
    margin-bottom: 40px;
  }
  
  .projects-slider {
    margin: 0 -10px;
  }
  
  .project-card {
    position: relative;
    margin: 0 10px;
    overflow: hidden;
  }
  
  .project-card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(138, 43, 226, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .project-card:hover .project-overlay {
    opacity: 1;
  }
  
  .project-content {
    color: #fff;
  }
  
  .project-content h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .project-content p {
    font-size: 14px;
  }
  
  .project-button {
    align-self: flex-end;
    background-color: #fff;
    color: #8a2be2;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .project-button:hover {
    background-color: #8a2be2;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .projects-header {
      flex-direction: column;
    }
  
    .projects-summary {
      max-width: 100%;
      text-align: left;
      margin-top: 10px;
    }
  }