/* src/components/OfferingsSection.css */
.offerings-section {
    background-color: rgb(29, 23, 41);
    padding: 100px 0 80px;
    margin-top: -50px;
    padding-bottom: 150;
  }
  
  .offerings-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .offerings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .offerings-subtitle {
    color: #8a2be2;
    font-size: 18px;
    margin: 0;
  }
  
  .offerings-summary {
    color: #fff;
    font-size: 16px;
    max-width: 50%;
    text-align: right;
  }
  
  .offerings-title {
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .offerings-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
  
  .offering-box {
    background-color: rgb(21, 16, 30);
    padding: 30px 20px;
    text-align: center;
    position: relative;
    border-radius: 8px;
  }
  
  .offering-icon-circle {
    width: 60px;
    height: 60px;
    background-color: rgb(21, 16, 30);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -60px auto 20px;
    font-size: 24px;
    color: #8a2be2;
  }
  
  .offering-box h3 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .offering-box p {
    color: #ccc;
    font-size: 14px;
  }
  
  @media (max-width: 1200px) {
    .offerings-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .offerings-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .offerings-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .offerings-summary {
      max-width: 100%;
      text-align: left;
      margin-top: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .offerings-grid {
      grid-template-columns: 1fr;
    }
  }