.product-manager-section {
    padding: 100px 0;
    background-color: #f8f9fa;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .product-manager-section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .product-manager-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
  }
  
  .product-manager-info {
    flex: 1;
    padding-right: 50px;
  }
  
  .product-manager-image-container {
    flex: 1;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    height: 400px; /* Set a fixed height for the container */
    width: 100%; /* Ensure the container takes up the available width */
  }
  
  .product-manager-image {
    width: 100%;
    height: 100%; /* Ensure the image fills the container */
    object-fit: cover; /* Crop the image to fit the container */
    transition: transform 0.3s ease;
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .product-manager-image-container:hover .product-manager-image {
    transform: scale(1.05);
  }
  
  .product-manager-image-container:hover .image-overlay {
    opacity: 1;
  }
  
  .product-manager-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .product-manager-description {
    font-size: 1.1em;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .product-manager-stats {
    display: flex;
    justify-content: space-between;
  }
  
  .stat {
    text-align: center;
  }
  
  .stat-number {
    display: block;
    font-size: 2em;
    font-weight: bold;
    color: #007bff;
  }
  
  .stat-label {
    font-size: 0.9em;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .product-manager-content {
      flex-direction: column;
    }
  
    .product-manager-info {
      padding-right: 0;
      padding-top: 30px;
    }

    .product-manager-image-container {
      height: 300px; /* Adjust height for smaller screens */
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .product-manager-title, .product-manager-description, .stat {
    opacity: 0;
    animation: fadeInUp 0.8s ease forwards;
  }
  
  .product-manager-title {
    animation-delay: 0.2s;
  }
  
  .product-manager-description {
    animation-delay: 0.4s;
  }
  
  .stat:nth-child(1) {
    animation-delay: 0.6s;
  }
  
  .stat:nth-child(2) {
    animation-delay: 0.8s;
  }
  
  .stat:nth-child(3) {
    animation-delay: 1s;
  }


/* .product-manager-section {
    padding: 100px 0;
    background-color: #f8f9fa;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .product-manager-section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .product-manager-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
  }
  
  .product-manager-info {
    flex: 1;
    padding-right: 50px;
  }
  
  .product-manager-image-container {
    flex: 1;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .product-manager-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .product-manager-image-container:hover .product-manager-image {
    transform: scale(1.05);
  }
  
  .product-manager-image-container:hover .image-overlay {
    opacity: 1;
  }
  
  .product-manager-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .product-manager-description {
    font-size: 1.1em;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .product-manager-stats {
    display: flex;
    justify-content: space-between;
  }
  
  .stat {
    text-align: center;
  }
  
  .stat-number {
    display: block;
    font-size: 2em;
    font-weight: bold;
    color: #007bff;
  }
  
  .stat-label {
    font-size: 0.9em;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .product-manager-content {
      flex-direction: column;
    }
  
    .product-manager-info {
      padding-right: 0;
      padding-top: 30px;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .product-manager-title, .product-manager-description, .stat {
    opacity: 0;
    animation: fadeInUp 0.8s ease forwards;
  }
  
  .product-manager-title {
    animation-delay: 0.2s;
  }
  
  .product-manager-description {
    animation-delay: 0.4s;
  }
  
  .stat:nth-child(1) {
    animation-delay: 0.6s;
  }
  
  .stat:nth-child(2) {
    animation-delay: 0.8s;
  }
  
  .stat:nth-child(3) {
    animation-delay: 1s;
  } */