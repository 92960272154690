/* src/components/AboutSection.css */
.about-section {
    padding: 80px 0;
    background-color: #f8f8f8;
  }

  .about-banner {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
  }
  
  .about-banner-content {
    text-align: center;
  }
  
  .about-banner-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-banner-subtitle {
    font-size: 18px;
  }
  
  .about-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 60px;
  }
  
  .about-images {
    position: relative;
    flex: 1;
  }
  
  .about-image-1 {
    width: 80%;
    height: auto;
    border-radius: 8px;
  }
  
  .about-image-2 {
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 60%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-content {
    flex: 1;
  }
  
  .about-subtitle {
    font-size: 18px;
    color: #8a2be2;
    margin-bottom: 10px;
  }
  
  .about-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .about-text {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    margin-bottom: 30px;
  }
  
  .about-services {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
  }
  
  .service-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .service-icon {
    font-size: 24px;
    color: #8a2be2;
  }
  
  .about-checklist {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
  }
  
  .about-checklist li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .check-icon {
    color: #8a2be2;
  }
  
  .learn-more-btn {
    background-color: #8a2be2;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-btn:hover {
    background-color: #000;
  }