.navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.logo {
  /* background-color: black; */
  display: flex;
  align-items: center;
  height: 60px;
}

.logo img {
  height: 100%;
  max-height: 50px;
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.1)); 
}

.welcome-text {
  font-size: 14px;
  color: #333;
}

.contact-info {
  display: flex;
  align-items: center;
}

.contact-info span {
  margin-right: 20px;
  font-size: 14px;
  position: relative;
}

.contact-info .email:hover::after,
.nav-right .phone:hover::after {
  width: 100%;
}

.contact-info .email::after,
.nav-right .phone::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: #8a2be2;
  transition: width 0.3s ease;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.navbar-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.nav-links {
  display: flex;
  list-style-type: none;
  gap: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #8a2be2;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-right span {
  font-size: 14px;
  position: relative;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.mobile-logo {
  display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-top {
    display: none;
  }

  .navbar-bottom {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-logo {
    display: flex;
    order: 2;
    flex-grow: 1;
    justify-content: center;
  }

  .mobile-logo .logo {
    height: 40px;
    /* background-color: transparent; */
  }

  .mobile-logo img {
    max-height: 40px;
  }

  .menu-icon {
    display: block;
    order: 1;
  }

  .nav-right {
    order: 3;
  }

  .nav-right .phone {
    display: none;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    order: 4;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }
}