/* src/components/TestimonialSection.css */
.testimonial-section {
    padding: 80px 0;
    background-color: #f8f8f8;
  }
  
  .testimonial-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .testimonial-subtitle {
    color: #8a2be2;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .testimonial-title {
    font-size: 36px;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .testimonial-slider {
    margin: 0 -15px;
  }
  
  .testimonial-card {
    margin: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .testimonial-image-container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    border: 3px solid #fff;
  }
  
  .testimonial-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .testimonial-info h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .testimonial-info p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #666;
  }
  
  .testimonial-content {
    padding: 20px;
  }
  
  .testimonial-rating {
    margin-bottom: 10px;
  }
  
  .star-icon {
    color: #ffc107;
    margin-right: 2px;
  }
  
  .testimonial-content p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .testimonial-card {
      margin: 10px;
    }
  }