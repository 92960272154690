/* src/components/FeaturesSection.css */
.features-section {
  padding: 80px 0;
  background-color: #fff;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.features-section.animate {
  opacity: 1;
}

.features-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.feature-card {
  width: 350px;
  position: relative;
}

.feature-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.feature-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  display: block;
}

.feature-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #8a2be2;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 20px;
}

.feature-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.feature-content h3 {
  margin: 0;
  font-size: 18px;
  text-align: center;
  color: #333;
}

@media (max-width: 1200px) {
  .features-container {
    flex-wrap: wrap;
  }

  .feature-card {
    width: calc(50% - 15px);
  }
}

@media (max-width: 768px) {
  .feature-card {
    width: 100%;
  }
}
