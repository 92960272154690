footer {
    width: 100%;
    color: white;
  }
  
  .footer-upper {
    background-color: rgb(29, 23, 41);
    padding: 40px 20px;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-column {
    flex: 1;
    min-width: 200px;
    margin: 20px;
  }
  
  .footer-company {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .footer-description {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .footer-social-icons {
    display: flex;
    gap: 10px;
  }
  
  .social-icon {
    font-size: 24px;
    background-color: white;
    color: rgb(29, 23, 41);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .social-icon:hover {
    background-color: #8a2be2;
    color: white;
  }
  
  .footer-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .footer-links-border {
    width: 30px;
    height: 2px;
    background-color: white;
    margin-bottom: 10px;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    padding-bottom: 2px;
    overflow: hidden;
  }
  
  .footer-links li::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #8a2be2;
    bottom: 0;
    left: -100%;
    transition: left 0.3s ease;
  }
  
  .footer-links li:hover::before {
    left: 0;
  }
  
  .footer-newsletter-text {
    margin-bottom: 20px;
  }
  
  .footer-newsletter-signup {
    display: flex;
    border: 2px solid #fff;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .footer-newsletter-signup input[type="email"] {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 0;
    color: #333;
  }
  
  .footer-newsletter-signup button {
    padding: 10px;
    border: none;
    background-color: white;
    color: rgb(29, 23, 41);
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .footer-newsletter-signup button:hover {
    background-color: #8a2be2;
    color: white;
  }
  
  .footer-checkbox {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .footer-checkbox input[type="checkbox"] {
    margin-right: 10px;
    accent-color: #8a2be2; /* Suitable color for the checkbox */
  }
  
  .footer-lower {
    background-color: rgb(21, 16, 30);
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-column p {
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    padding-bottom: 2px;
    overflow: hidden;
  }
  
  .footer-column p::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #8a2be2;
    bottom: 0;
    left: -100%;
    transition: left 0.3s ease;
  }
  
  .footer-column p:hover::before {
    left: 0;
  }
  