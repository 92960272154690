.trainer-section {
    padding: 4rem 2rem;
    background-color: #f5f5f5;
  }
  
  .trainer-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .trainer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  @media (max-width: 768px) {
    .trainer-grid {
      grid-template-columns: 1fr;
    }
  }