.trainer-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Extended duration to 1s */
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Initial position */
}

.trainer-card.animate {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Move to final position */
}

.trainer-card:hover {
  transform: translateY(-5px);
}

.trainer-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.trainer-info {
  padding: 1rem;
}

.trainer-info h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.trainer-info h4 {
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #666;
}

.trainer-info p {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 0.5rem;
}

.experience {
  font-size: 0.8rem;
  color: #999;
  display: block;
}
