.service-page {
  width: 100%;
  overflow-x: hidden;
}

.service-banner {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
}

.service-banner-content {
  text-align: center;
}

.service-banner-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
}

.service-banner-subtitle {
  font-size: 18px;
}

.about-section {
  padding: 80px 20px;
  background-color: #f8f8f8;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 60px;
}

.about-images {
  position: relative;
  flex: 0 0 40%;
  max-width: 400px;
  height: 400px; /* Set a fixed height */
}

.about-image-1 {
  width: 80%;
  height: auto;
  max-height: 80%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  object-fit: cover;
}

.about-image-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: auto;
  max-height: 70%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  object-fit: cover;
}

.about-content {
  flex: 1;
}

.about-subtitle {
  font-size: 18px;
  color: #8a2be2;
  margin-bottom: 10px;
}

.about-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.about-text {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 30px;
}

.about-services {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
}

.service-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.about-checklist {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.about-checklist li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.learn-more-btn {
  background-color: #8a2be2;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.learn-more-btn:hover {
  background-color: #000;
}

.cards-section {
  padding: 40px 20px;
  background-color: #fff;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-image-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
}

.card-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fff;
  object-fit: cover;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 16px;
  color: #666;
  flex-grow: 1;
}

.more-services-section {
  padding: 80px 20px;
  background-color: #f8f8f8;
}

.more-services-container {
  max-width: 1200px;
  margin: 0 auto;
}

.more-services-content {
  width: 100%;
}

.more-services-subtitle {
  font-size: 18px;
  color: #8a2be2;
  margin-bottom: 10px;
}

.more-services-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.more-services-columns {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.more-service-column {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.more-service-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  flex-shrink: 0;
}

.more-service-text-container {
  flex-grow: 1;
}

.more-service-text {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}

.read-more-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  text-decoration: underline;
}

.read-more-btn:hover {
  color: #0056b3;
}

/* Styles for desktop and large tablets */
@media (min-width: 1025px) {
  .about-container {
    flex-direction: row;
  }

  .about-images {
    display: block;
  }
}


@media (min-width: 769px) {
  .more-services-columns {
    flex-direction: row;
  }
  .about-images {
    display: block;
  }
}

@media (max-width: 768px) {
  
  .service-banner-title {
    font-size: 36px;
  }

  .about-title {
    font-size: 28px;
  }

  .card {
    max-width: 100%;
  }

  .more-services-title {
    font-size: 28px;
  }
  .about-container {
    flex-direction: column;
  }

  .about-images {
    display: none; /* Hide images on small devices */
  }

  .about-content {
    width: 100%;
  }

}

/* Styles for tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .about-container {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .about-images {
    flex: 0 0 35%;
    max-width: 300px;
    height: 300px;
    margin-bottom: 0;
  }

  .about-image-1 {
    width: 75%;
    max-height: 75%;
  }

  .about-image-2 {
    width: 65%;
    bottom: 65px;
  }
  .about-content {
    flex: 1;
  }
}