/* src/components/ReadySection.css */
.ready-section {
    margin-top: -100px;
    padding: 0 20px;
    /* position: relative; */
  }
  
  .ready-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 40px 60px;
    position: relative;
    z-index: 1;
    background-image: url('../assets/images/AboutUs_img2.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .ready-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(172, 118, 248, 0.8);
    z-index: -1;
  }
  
  .ready-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ready-image img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .ready-text {
    flex: 1;
    padding: 0 50px;
  }
  
  .ready-text h2 {
    font-size: 36px;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .ready-benefits {
    list-style-type: none;
    padding: 0;
  }
  
  .ready-benefits li {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .check-icon {
    margin-right: 10px;
    color: #fff;
  }
  
  .ready-button {
    background-color: #8a2be2;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ready-button:hover {
    background-color: #000;
  }
  
  @media (max-width: 1024px) {
    .ready-container {
      margin: 0 20px;
    }
  }
  
  @media (max-width: 768px) {
    .ready-content {
      flex-direction: column;
      text-align: center;
    }
  
    .ready-image {
      margin-bottom: 30px;
    }
  
    .ready-text {
      padding: 0;
    }
  
    .ready-benefits li {
      justify-content: center;
    }
  
    .ready-button {
      margin-top: 30px;
    }
  }