.cta-section {
    background-color: rgb(95, 45, 238);
    padding: 40px 0;
  }
  
  .cta-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .cta-text {
    color: white;
  }
  
  .cta-subtitle {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .cta-title {
    font-size: 36px;
    font-weight: bold;
  }
  
  .cta-button {
    background-color: white;
    color: rgb(95, 45, 238);
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: rgb(75, 35, 188);
    color: white;
  }
  